"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LimitedStockNotificationAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_components_1 = require("@as-react/common-components");
const react_redux_1 = require("react-redux");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const common_utils_1 = require("@as-react/common-utils");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const LimitedStockNotificationAlpha = () => {
    const { oneSizeProductCode, market } = (0, common_components_1.useAemConfig)();
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const sizes = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductSizes)());
    const skuAvailability = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSkuAvailability)());
    const sizeCombinationNotExist = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSizeCombinationNotExist)());
    const oneSizeProduct = (0, common_utils_1.isOneSizeProduct)(sizes, oneSizeProductCode);
    const getMessage = () => {
        if (sizeCombinationNotExist) {
            return t(`pdp.size.combination.${market === 'be' ? 'be.' : ''}not.exist.error`);
        }
        if (skuAvailability.isSoldOut && !oneSizeProduct) {
            return t(`product.selected.size.${market === 'be' ? 'be.' : ''}unavailable`);
        }
        if (skuAvailability.exactAvailability && skuAvailability.exactAvailability > 0) {
            return t('product.last.pieces.exact', {
                amount: skuAvailability.exactAvailability,
            });
        }
        if (skuAvailability.lastPieces) {
            return t('product.last.pieces');
        }
        return '';
    };
    const message = getMessage();
    return message ? ((0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { itemsAlign: "center", gap: "1", children: [(0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.InfoIcon, color: "error", size: "xs" }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { color: "error", variant: "bodyS", children: message })] })) : null;
};
exports.LimitedStockNotificationAlpha = LimitedStockNotificationAlpha;
