"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliveryPromiseMessage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const andes_react_1 = require("@yonderland/andes-react");
const andes_icons_1 = require("@yonderland/andes-icons");
const useCountdownTimer_1 = require("../../hooks/useCountdownTimer");
const DeliveryConstants_1 = require("../../constants/DeliveryConstants");
const deliveryPromiseConfig = {
    'pdp+basket': {
        cc: {
            direct: { status: 'complete' },
            delayed: { status: 'partiallyComplete' },
            unavailable: { status: 'unavailable' },
        },
        ccSpecific: {
            direct: { status: 'complete' },
            delayed: { status: 'partiallyComplete' },
            unavailable: { status: 'incomplete' },
        },
        hd: {
            direct: { status: 'complete', color: 'success', emphasized: true },
            delayed: { status: 'partiallyComplete', emphasized: true },
            unavailable: { status: 'unavailable' },
        },
    },
    'product-tile': {
        cc: {
            direct: { status: 'complete' },
            delayed: { status: 'partiallyComplete' },
            unavailable: { status: 'unavailable' },
        },
        ccSpecific: {
            direct: { status: 'complete' },
            delayed: { status: 'partiallyComplete' },
            unavailable: { status: 'incomplete' },
        },
        hd: {
            direct: { status: 'complete', color: 'success' },
            delayed: { status: 'partiallyComplete' },
            unavailable: { status: 'unavailable' },
        },
    },
    ssm: {
        cc: {
            direct: { status: 'complete' },
            delayed: { status: 'partiallyComplete' },
            unavailable: { status: 'incomplete' },
        },
    },
};
const DeliveryPromiseMessage = ({ state, touchPoint, option, message, standardDeliveryLabel, dataTestId, type, explanation, cutoff, cta, }) => {
    var _a, _b;
    const { countdownMessage } = (0, useCountdownTimer_1.useCountdownTimer)(cutoff, message);
    if (!message || !countdownMessage)
        return null;
    const { status, color, emphasized } = (_b = (_a = deliveryPromiseConfig[touchPoint]) === null || _a === void 0 ? void 0 : _a[option]) === null || _b === void 0 ? void 0 : _b[state];
    const isCountdownTimer = cutoff && message.includes(DeliveryConstants_1.COUNTDOWN) && DeliveryConstants_1.ENABLED_DELIVERY_PROMISES.includes(type || '');
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StatusIndicator, { status: status, dataTestId: dataTestId, children: [(0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { as: "span", color: color, strong: emphasized, children: [standardDeliveryLabel && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [standardDeliveryLabel, (0, jsx_runtime_1.jsx)("br", {})] })), isCountdownTimer ? (0, jsx_runtime_1.jsx)("span", { dangerouslySetInnerHTML: { __html: countdownMessage } }) : message] }), cta && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [cta.prefix ? ` ${cta.prefix} ` : ' ', (0, jsx_runtime_1.jsx)(andes_react_1.LinkButton, { text: (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { as: "span", strong: true, children: cta.text }), onClick: cta.onClick })] })), explanation && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [' ', (0, jsx_runtime_1.jsx)(andes_react_1.Tooltip, { text: explanation, hasCloseBtn: false, children: (0, jsx_runtime_1.jsx)(andes_react_1.IconAlpha, { source: andes_icons_1.InfoIcon, size: "xs", color: "success" }) })] }))] }));
};
exports.DeliveryPromiseMessage = DeliveryPromiseMessage;
