"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReviewRatingAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const common_types_1 = require("@as-react/common-types");
const react_i18next_1 = require("react-i18next");
const useReevoo_1 = require("../../../hooks/useReevoo");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const andes_react_1 = require("@yonderland/andes-react");
const useFeatureToggle_1 = require("../../../hooks/useFeatureToggle");
const react_redux_1 = require("react-redux");
const ProductDetailV2Container_1 = require("../../../containers/product/ProductDetailV2Container");
const ScrollUtil_1 = require("../../../util/ScrollUtil");
const ProductActions_1 = require("../../../redux/product/actions/ProductActions");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const ReviewRatingAlpha = ({ review, productCode, productDetailTabs }) => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const show_sticky_bar_pdp = (0, useFeatureToggle_1.useFeatureToggle)('show_sticky_bar_pdp');
    const dispatch = (0, react_redux_1.useDispatch)();
    const { showTabsAsAccordion } = (0, ProductDetailV2Container_1.useProductDetailContext)();
    const { isMd, isLg, isXl, is2xl } = (0, andes_react_1.useBreakpoints)();
    const expertReviews = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetExpertReviews)());
    const isDesktop = isLg || isXl || is2xl;
    const isTablet = isMd;
    const reviewTab = productDetailTabs === null || productDetailTabs === void 0 ? void 0 : productDetailTabs.find(tab => tab.type === common_types_1.ProductDetailTabTypes.REVIEWS);
    const onReviewRatingClick = () => {
        const reviewTabEl = document.getElementById(`product_detail_tab_${common_types_1.ProductDetailTabTypes.REVIEWS}`);
        if (reviewTabEl) {
            if (showTabsAsAccordion) {
                const scrollingOffset = ScrollUtil_1.ScrollUtil.getScrollingOffset(isDesktop, isTablet, show_sticky_bar_pdp);
                reviewTabEl.parentElement &&
                    ScrollUtil_1.ScrollUtil.scrollToPosition(reviewTabEl.parentElement.offsetTop - scrollingOffset, 0, true);
            }
            else {
                ScrollUtil_1.ScrollUtil.scrollToElement(reviewTabEl, false, 'center');
            }
            dispatch(ProductActions_1.productActions.createSetSelectedProductTab(`product_detail_tab_${common_types_1.ProductDetailTabTypes.REVIEWS}`));
        }
    };
    const amountTranslation = review.numberOfScores > 1
        ? t('product.review.add.amount.of.multiple.review.react', {
            amountOfReviews: review.numberOfScores,
        })
        : t('product.review.add.amount.of.one.review.react', {
            amountOfReviews: review.numberOfScores,
        });
    const ctaText = amountTranslation;
    const showReevooConversationsBadge = (review === null || review === void 0 ? void 0 : review.provider) === common_types_1.ReviewProviderTypes.REEVOO && !!productCode;
    (0, useReevoo_1.useReevoo)(showReevooConversationsBadge);
    if (!review) {
        return null;
    }
    const getHasReviewTab = () => reviewTab &&
        ((review && (reviewTab.showEndUserReview || !!reviewTab.reviewTitle)) ||
            (expertReviews.length > 0 && reviewTab.showExpertReview));
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "1", itemsAlign: "center", children: [(0, jsx_runtime_1.jsx)(andes_react_1.Rating, { dataTestId: "product_rating", rating: review.avgScore, readOnly: true }), (0, jsx_runtime_1.jsx)(andes_react_1.Link, { dataTestId: "product_rating_link", onClick: getHasReviewTab() ? onReviewRatingClick : undefined, children: ctaText })] }));
};
exports.ReviewRatingAlpha = ReviewRatingAlpha;
