"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorOutOfStockMessageAlpha = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_i18next_1 = require("react-i18next");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const andes_react_1 = require("@yonderland/andes-react");
const ColorOutOfStockMessageAlpha = () => {
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    return ((0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { variant: "bodyS", strong: true, children: t('product.colorNotAvailable') }));
};
exports.ColorOutOfStockMessageAlpha = ColorOutOfStockMessageAlpha;
