"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorSwatchesAlpha = exports.generateSwatchFromProductColorVariation = exports.getDiscountIconContent = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const i18nConstants_1 = require("../../../constants/i18nConstants");
const react_i18next_1 = require("react-i18next");
const andes_react_1 = require("@yonderland/andes-react");
const ImageUtil_1 = require("../../../util/ImageUtil");
const react_redux_1 = require("react-redux");
const common_components_1 = require("@as-react/common-components");
const ProductActions_1 = require("../../../redux/product/actions/ProductActions");
const ProductSelector_1 = require("../../../redux/product/selectors/ProductSelector");
const getDiscountIconContent = (productSwatch, productSwatchConfig) => {
    let iconContent = '';
    if (productSwatch.hasDiscount) {
        iconContent = productSwatchConfig.actionLabel;
        if (productSwatch.markdown) {
            iconContent = productSwatchConfig.saleLabel;
        }
    }
    return iconContent;
};
exports.getDiscountIconContent = getDiscountIconContent;
const generateSwatchFromProductColorVariation = (productColorVariation, selectedColorId, productCode, productImageConfig, swatchInformation) => ({
    id: `${productCode}-${productColorVariation.colorId}`,
    value: productColorVariation.colorId.toString(),
    colourId: productColorVariation.colorId,
    color: productColorVariation.translations.description,
    extendedRange: productColorVariation.webOnly,
    swatch: (0, ImageUtil_1.getSwatchUrlByProductCode)(productCode, productColorVariation.swatch, productImageConfig),
    imageUrl: (0, ImageUtil_1.getSwatchUrlByProductCode)(productCode, productColorVariation.swatch, productImageConfig),
    selected: selectedColorId === productColorVariation.colorId,
    hasDiscount: Boolean(productColorVariation.hasDiscount),
    hasPermanentDiscount: Boolean(productColorVariation.markdown),
    outOfStock: productColorVariation.availability === 0,
    textOverlay: (0, exports.getDiscountIconContent)(productColorVariation, swatchInformation),
    size: 'md',
    lazyLoad: true,
});
exports.generateSwatchFromProductColorVariation = generateSwatchFromProductColorVariation;
const ColorSwatchesAlpha = ({ enableShopTheLook }) => {
    const { feature_toggles: { categorized_product_features }, pageInfo: { isPDPPage }, productImageConfig, productSwatchConfig, } = (0, common_components_1.useAemConfig)();
    const dispatch = (0, react_redux_1.useDispatch)();
    const colors = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetAvailableColors)());
    const productCode = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetProductDefaults)()).productCode;
    const selectedColorId = (0, react_redux_1.useSelector)((0, ProductSelector_1.makeGetSelectedColorId)());
    const { t } = (0, react_i18next_1.useTranslation)(i18nConstants_1.I18N_NAMESPACES.product);
    const [description, setDescription] = (0, react_1.useState)();
    const swatches = colors.map((productColor) => (0, exports.generateSwatchFromProductColorVariation)(productColor, selectedColorId, productCode, productImageConfig.cdnDomains, productSwatchConfig));
    (0, react_1.useEffect)(() => {
        const selectedColorVariation = colors.find((colorVariation) => colorVariation.colorId === selectedColorId);
        if (!selectedColorVariation) {
            return;
        }
        setDescription(selectedColorVariation.translations.description);
    }, [swatches, selectedColorId]);
    const setProductColor = (colourId) => {
        dispatch(ProductActions_1.productActions.createSetColorAction(colourId));
        if (isPDPPage && categorized_product_features) {
            dispatch(ProductActions_1.productActions.createGetTechnicalCategories(colourId));
        }
        if (enableShopTheLook) {
            dispatch(ProductActions_1.productActions.createGetLinkedProducts(colourId));
        }
    };
    const handleChange = (colourId) => {
        if (Number(colourId) !== selectedColorId) {
            setProductColor(Number(colourId));
        }
    };
    return ((0, jsx_runtime_1.jsxs)(andes_react_1.StackBlock, { gap: "1", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.StackInline, { gap: "1", children: [(0, jsx_runtime_1.jsxs)(andes_react_1.TextAlpha, { children: [t('product:pdp.color'), ": "] }), (0, jsx_runtime_1.jsx)(andes_react_1.TextAlpha, { strong: true, color: "emphasised", dataTestId: "color_name", children: description })] }), swatches && swatches.length > 1 && ((0, jsx_runtime_1.jsx)(andes_react_1.SwatchSelector, { name: `color_swatch_${productCode}`, defaultValue: String(selectedColorId), onChange: handleChange, swatches: swatches }))] }));
};
exports.ColorSwatchesAlpha = ColorSwatchesAlpha;
